import React from "react";

const Footer = (props) => {

  return (
    <footer className={props.fixed ? "fixed" : ''}>
        <div className="legals">
            <div className="legals">
                <p>
                    <span className="smeuk">© <span id="footerDate">{new Date().getFullYear()}</span>  <a href="https://www.sonymusic.co.uk" target="_blank" rel="noopener noreferrer">Sony Music Entertainment UK Ltd.</a></span>
                    <span className="sep"> | </span>
                    <span className="privacy"><a href="https://www.sonymusic.co.uk/privacy" target="_blank" rel="noopener noreferrer">Privacy &amp; Cookie Policy</a></span>
                </p>
            </div>
        </div>
    </footer>
  )
}

export default Footer