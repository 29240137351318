import * as React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby";

const StyledLink = styled(GatsbyLink)`
  background: black;
  color: white;
  text-decoration: none;
  border-radius: 10px;
  padding: 1.5rem 2rem;
  font-weight: 700;
  box-shadow: none;
  cursor: pointer;
  display: ${props => props.fullwidth ? "block" : "flex"};
  width: ${props => props.fullwidth ? "100%" : "auto"};
  align-items: center;
  justify-content: center;
  transform: translateY(3px);
  transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: 0px 3px 10px 3px rgb(0 0 0 / 40%);
    transform: translateY(0);
  }
`

const StyledButton = styled.button`
  background: black;
  color: white;
  text-decoration: none;
  border: none;
  outline: none;
  border-radius: 10px;
  margin: 0.5rem auto;
  padding: 1.5rem 2rem;
  font-weight: 700;
  box-shadow: none;
  cursor: pointer;
  display: ${props => props.fullwidth ? "block" : "flex"};
  width: ${props => props.fullwidth ? "100%" : "auto"};
  align-items: center;
  justify-content: center;
  transform: translateY(3px);
  transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: 0px 3px 10px 3px rgb(0 0 0 / 40%);
    transform: translateY(0);
  }
`

const Button = (props) => {
  return (
    props.type === "link" ?
      <StyledLink 
        to={props.link}
        fullwidth={Boolean(props.fullwidth) || false}
        {...props}
      >
        {
          props.icon === '' ? '' : 
            <span className="icon">
              <i class={`fa fa-${props.icon}`}></i>
            </span>
        }
        {
          <span>{props.text}</span>
        }
      </StyledLink>
    :
    <StyledButton
      onClick={props.onClick ? props.onClick : null}
      fullwidth={props.fullwidth || false}
    >
      {
        props.icon === '' ? '' : 
          <span className="icon">
            <i class={`fa fa-${props.icon}`}></i>
          </span>
      }
      {
        <span>{props.text}</span>
      }
    </StyledButton>
  )
};

Button.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  fullwidth: PropTypes.bool
}

Button.defaultProps = {
  link: ``,
  icon: ``,
  text: ``,
  type: "button",
  fullwidth: false
}

export default Button;
