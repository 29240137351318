import React from 'react';
import styled from 'styled-components';

const BgImage = styled.div`
  background-image: url(${props => props.image ? props.image : ""});
  background-size: ${props => props.size};
  background-position: ${props => props.position};
  background-repeat: ${props => props.repeat};
  background-color: ${props => props.bgcolor ? props.bgcolor : "transparent"};
  height: ${props => props.height};
  top: ${props => {
    return props.location === "top" ? "0" : "unset"
  }};
  bottom: ${props => {
    return props.location === "bottom" ? "0" : "unset"
  }};
  left: 0;
  width: 100vw;
  position: fixed;
  z-index: -1;
`

const Background = (props) => {
  return (
    <BgImage
      {...props}
    />
  )
};

export default Background;
